import Slider from "react-slick";
import { useDispatch } from "react-redux";
import { Button } from "antd";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { addToCart } from "../pages/cart/cartModule";
import { Navigate } from "react-router-dom";
import {
  APP_NAME,
  STATIC_SLIDER_OPTIONS,
  SLIDER_OPTIONS,
  SET_CATEGORIES,
  RECENT_BOOKS,
  FREE_BOOKS,
} from "../constants";

import { getImage } from "../utils";

const SliderComponent = (props) => {
  console.log(props.items);
  const dispatch = useDispatch();

  return (
    <div className=" bg-blue-50 p-5 md:p-10 ">
      {props.hideTitle && (
        <div
          className="text-3xl text-center pb-10 text-blue-700"
          onClick={() => Navigate(`/book-store/${RECENT_BOOKS}`)}
          title="Go to book store"
        >
          Recently Added
        </div>
      )}
      <Slider {...props.settings}>
        {props.items.map((item) => {
          const dis = item.discount || false;

          return (
            <div
              key={item.name}
              className=" bg-white p-5  cursor-pointer rounded text-center"
            >
              <img
                src={getImage(item.image)}
                alt={item.name}
                className="h-60 w-64 object-cover"
              />
              {props.showName && (
                <h6 className="block text-base capitalize mt-5 font-bold text-blue-600">
                  {item.name}
                  {props.showFree && (
                    <span style={{ color: "red" }}> - Free</span>
                  )}
                </h6>
              )}
              {props.showPrice && (
                <h6
                  style={props.h6Style}
                  className="flex justify-center flex-col text-base mt-1 gap-3 items-center"
                >
                  {/* <i className="las la-wallet h-10 rounded-full text-blue-700 w-10 flex items-center justify-center bg-blue-200"></i> */}
                  <div className="">Rs. {item.price}</div>
                  {dis && <div className="discount-span"> ({dis}% off)</div>}
                </h6>
              )}
              {props.showBtn && (
                <Button
                  className="text-blue-800 opacity-60 hover:opacity-100 text-sm tracking-widest uppercase border-[1px] rounded-lg border-blue-900 bg-transparent h-auto mt-4 px-6 py-3 hover:bg-blue-700 hover:text-white "
                  onClick={() => dispatch(addToCart(item))}
                >
                  <i className="las la-wallet mr-3"></i>

                  {props.btnLabel}
                </Button>
              )}
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default SliderComponent;
