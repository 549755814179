import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { get } from "lodash";
import LocalStore from "../library/localStore";
import Logo from "../resources/logo.png";
import { ALL_BOOKS, LOG_OUT } from "../constants";
import { ShoppingCartOutlined, LoginOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { FaShoppingCart } from "react-icons/fa";


const SiteHeader = () => {
  const dispatch = useDispatch();
  const {
    cart: { cartItems },
  } = useSelector((state) => state);
  const navigate = useNavigate();
  const payload = LocalStore.decodeToken();
  const isLoggedIn =
    get(payload, "role") === "user" && Date.now() < payload.expiredAt;

  // Get search from URL and set state if exists otherwise set empty string
  const search =
    new URLSearchParams(window.location.search).get("search") || "";
  const [searchQuery, setSearchQuery] = React.useState(search);

  // Handle search
  const handleSearch = (e) => {
    e.preventDefault();
    navigate(`/search?query=${searchQuery}`);
  };

  // State to handle mobile menu toggle
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="shadow-sm bg-white dark:bg-gray-800 py-3">
      <nav className="px-4 lg:px-6 ">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
          <Link to="/" className="flex items-center">
            <img src={Logo} className="mr-3 h-8 sm:h-10" alt="Site Logo" />
            
          </Link>
          <form action="" className="relative  md:my-0  justify-between hidden sm:flex">
          <input
            type="text"
            className="bg-white w-full sm:w-64 md:w-96 px-4 py-3 border-[1px] border-blue-400 outline-0 focus:outline-0 rounded-full"
            placeholder="Search Books, Book Category and Institutions..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch(e);
              }
            }}
          />
          <i
            className="las la-search text-2xl absolute right-3 top-1/2 -translate-y-1/2  z-10  text-white rounded-full h-8 w-8 flex justify-center items-center bg-blue-500 scale-x-[-1] cursor-pointer"
            onClick={handleSearch}
          ></i>
        </form>

          <div className="flex items-center lg:order-2">
            {isLoggedIn ? (
              <>
                <Link
                  to="/user-profile"
                  className="text-gray-800 dark:text-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 py-2 mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"
                >
                  Profile
                </Link>
                <div
                  onClick={() => {
                    message.info("Logged out successfully!!");
                    dispatch({ type: LOG_OUT });
                    navigate("/");
                  }}
                  className="text-gray-800 dark:text-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 py-2 mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800 cursor-pointer"
                >
                  <LoginOutlined className="mr-2" />
                  Log Out
                </div>
              </>
            ) : (
              <div className="flex gap-3 md:gap-5">
              <div
              className="relative cursor-pointer flex items-center ml-4"
              onClick={() => navigate("/cart")}
            >
              
              <FaShoppingCart className="text-blue-700 text-xl" />
              <span className="absolute -top-3 -right-3 bg-blue-700 text-white text-xs font-semibold rounded-full px-2 py-0.5 border-2 border-white">
                {(cartItems || []).length}
              </span>
            </div>
              <Link
                to="/login"
                className="px-3 py-2 rounded-md bg-blue-500 text-white text-decoration-none hover:bg-blue-600"
              >
                Log in
              </Link>
              </div>
            )}

           

            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="inline-flex items-center bg-blue-200 text-blue-600 p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-6 h-6 text-blue-500"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>

          <div
            className={`${
              isMenuOpen ? "block" : "hidden"
            } w-full lg:flex lg:w-auto lg:order-1`}
          >
            <div className="">
            <form action="" className="relative mt-3 sm:mt-0  md:my-0  justify-between flex sm:hidden">
          <input
            type="text"
            className="bg-white w-full sm:w-64 md:w-96 px-4 py-3 border-[1px] border-blue-400 outline-0 focus:outline-0 rounded-full"
            placeholder="Search Books, Book Category and Institutions..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch(e);
              }
            }}
          />
          <i
            className="las la-search text-2xl absolute right-3 top-1/2 -translate-y-1/2  z-10  text-white rounded-full h-8 w-8 flex justify-center items-center bg-blue-500 scale-x-[-1] cursor-pointer"
            onClick={handleSearch}
          ></i>
        </form>

        <ul className="flex flex-col lg:flex-row gap-3 mt-4 lg:mt-0 p-0 font-medium">
              <li>
                <Link
                  to="/"
                  className="block text-decoration-none py-2 pr-4 pl-3 text-gray-700 rounded lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to={`/book-store/${ALL_BOOKS}`}
                  className="block text-decoration-none py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                >
                  Books
                </Link>
              </li>
              <li>
                <Link
                  to="/category-store"
                  className="block  text-decoration-none py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                >
                  Categories
                </Link>
              </li>
              <li>
                <Link
                  to="/institution-list"
                  className="block text-decoration-none py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                >
                  Institutions
                </Link>
              </li>
              <li>
                <Link
                  to="/book-store/all"
                  className="block text-decoration-none py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                >
                  Free Books
                </Link>
              </li>
            </ul>
            </div>
            
          </div>
        </div>
      </nav>
    </header>
  );
};

export default SiteHeader;
